export default (instance:string) => {
    return [{
        slogan: '城市高端酒店发展',
        pageTitle: '评估与展望 - '+instance,
        pageTitleIcon: '',
        background_img: '/backgrounds/sunny.jpg',
        icons: [
            {
                name: '大数据',
                img: '/icons/大数据.svg',
                background: 'black',
                url: `/v3/内容文件%2F2.城市?instance=${instance}`
            },
            {
                name: '趋势分析',
                img: '/icons/趋势分析.svg',
                background: 'black',
                url: `/report-viewer?report_path=/内容文件/2.城市/2.趋势/${instance}.pdf`
            },
            {
                name: '新开酒店',
                img: '/icons/新开酒店.svg',
                background: 'black',
                url: `/datapage/内容文件%2F2.城市%2F1.发展%2F5.新开酒店%2F?menu=${encodeURIComponent('城市/新开酒店')}&instance=${instance}`
            },
            {
                name: '时间线',
                img: '/icons/时间线.svg',
                background: 'black',
                url: `/report-viewer?report_path=/内容文件/2.城市/0.5.时间线/${instance}.png`
            },
            {
                name: '对比分析',
                img: '/icons/对比分析.svg',
                background: 'black',
                url: `/report-viewer?report_path=/内容文件/2.城市/3.对比/${instance}.pdf`
            },
            {
                name: '在建待建',
                img: '/icons/在建待建.svg',
                background: 'black',
                url: `/datapage/内容文件%2F2.城市%2F4.展望%2F2.在建待建酒店清单%2F?instance=${instance}&target_c2=在建待建酒店清单`
            },
        ]
    }]
}