
import { useUserInfoStore } from '~/stores/userInfoStore'
import axios from 'axios'

export default async (c1:string)=> {
    const userInfoStore = useUserInfoStore()
    switch(c1){
        case '项目':
            const projects = await axios.get('/api/v3/get-projects')
            console.log("projects", projects)
            return projects.data.payload.map((x:any)=>({
                name:x["0.项目名称"],
                url: "/datapage/内容文件%2F1.项目%2F"
            }))
        case '城市':
            if(userInfoStore.info["可选城市"] == '全部'){
                return [
                    "北京",
                    "上海",
                    "深圳",
                    // "广州",
                ].map(x=>({name:x}))
            }else if(userInfoStore.info["可选城市"] == '关闭'){
                return []
            }else{
                const optionsString = userInfoStore.info["可选城市"]
                const options = optionsString ? optionsString.split(',') : []
                return options.map(x=>({name:x}))
            }
        case '酒店':
            if(userInfoStore.info["可选酒店"] == '全部'){
                return [
                    "北京新世界酒店",
                    "北京日出东方凯宾斯基酒店",
                    "北京雁栖岛国宾别墅",
                    "北京雁栖酒店",
                    "北京燕莎中心凯宾斯基饭店",
                    "北京世园凯悦酒店",
                    "北京望京凯悦酒店",
                ].map(x=>({name:x}))
            }else if(userInfoStore.info["可选酒店"] == '关闭'){
                return []
            }else{
                const optionsString = userInfoStore.info["可选酒店"]
                const options = optionsString ? optionsString.split(',') : []
                return options.map(x=>({name:x}))
            }
        case '品牌':
            if(userInfoStore.info["可选品牌"] == '全部'){
                return [
                    "万豪",
                    "希尔顿",
                    "柏悦",
                ].map(x=>({name:x}))
            }else if(userInfoStore.info["可选品牌"] == '关闭'){
                return []
            }else{
                const optionsString = userInfoStore.info["可选品牌"]
                const options = optionsString ? optionsString.split(',') : []
                return options.map(x=>({name:x}))
            }
        case '集团':
            if(userInfoStore.info["可选集团"] == '全部'){
                return [
                    "万豪",
                    "希尔顿",
                    "华住",
                    "锦江",
                ].map(x=>({name:x}))
            }else if(userInfoStore.info["可选集团"] == '关闭'){
                return []
            }else{
                const optionsString = userInfoStore.info["可选集团"]
                const options = optionsString ? optionsString.split(',') : []
                return options.map(x=>({name:x}))
            }
        case '研报':
            if(userInfoStore.info.user_type == '华美员工'){
                return [
                    {
                        name: '减少浴缸',
                        file_path: '/内容文件/7.华美/3.华美研究/0.5.最新研究/5.减少浴缸.pdf'
                    },
                    {
                        name: '晚一天',
                        file_path: '/内容文件/7.华美/3.华美研究/0.5.最新研究/4.晚一天.pdf'
                    },
                    {
                        name: '空酒店',
                        file_path: '/内容文件/7.华美/3.华美研究/0.5.最新研究/3.空酒店.pdf'
                    },
                ].map(x=>({
                    name: x.name,
                    open_url: `/report-viewer?report_path=${encodeURIComponent(x.file_path)}`
                }))
            }else{
                return []
            }
    }
}