export default (instance:string) => {
    return [{
        slogan: '华美服务 = 咨询服务 + 华美云 + 尽管问',
        pageTitle: '预算审核',
        pageTitleIcon: '',
        background_img: '/backgrounds/sunny.jpg',
        icons: [
            {
                name: '预算审核',
                img: '/icons/预算审核.svg',
                background: 'black',
                url: `/report-viewer?skip_decoding=1&report_path=${encodeURI("https://hm-cloud-v3.oss-cn-shenzhen.aliyuncs.com/华美服务介绍/预算审核.pdf")}`
            },
            {
                name: '成功案例',
                img: '/icons/成功案例.svg',
                background: 'black',
                url: `/project-list?category=华美服务&instance=预算审核`
            },
            {
                name: '',
                img: '',
                background: 'black',
                url: ``
            },
            {
                name: '',
                img: '',
                background: 'black',
                url: ``
            },
            {
                name: '数据库',
                img: '/icons/数据库.svg',
                background: 'black',
                url: `/browser?menu_path=${encodeURIComponent('预算审核/数据库')}`
            },
            {
                name: '',
                img: '',
                background: 'black',
                url: ``
            },
            {
                name: '',
                img: '',
                background: 'black',
                url: ``
            },
            {
                name: '',
                img: '',
                background: 'black',
                url: ``
            },
            {
                name: '案例库',
                img: '/icons/案例库.svg',
                background: 'black',
                url: `/browser?menu_path=${encodeURIComponent('预算审核/案例库')}`
            },
            {
                name: '',
                img: '',
                background: 'black',
                url: ``
            },
            {
                name: '',
                img: '',
                background: 'black',
                url: ``
            },
            {
                name: '',
                img: '',
                background: 'black',
                url: ``
            },
            {
                name: '问题库',
                img: '/icons/工具库.svg',
                background: 'black',
                url: `/browser?menu_path=${encodeURIComponent('预算审核/问题库')}`
            },
            {
                name: '预算审核常见问题',
                img: '/icons/文件.svg',
                background: 'black',
                url: `/datapage/内容文件%2f6.资管%2f2.预算审核%2f2.问题库—预算审核%2f预算审核常见问题.json`
            },
            {
                name: '',
                img: '',
                background: 'black',
                url: ``
            },
            {
                name: '',
                img: '',
                background: 'black',
                url: ``
            },
            {
                name: '工具库',
                img: '/icons/工具库.svg',
                background: 'black',
                url: `/browser?menu_path=${encodeURIComponent('预算审核/工具库')}`
            },
            {
                name: '尽管问',
                img: '/icons/尽管问_白.svg',
                background: 'black',
                url: `/datapage/尽管问`
            },
            {
                name: '',
                img: '',
                background: 'black',
                url: ``
            },
            {
                name: '',
                img: '',
                background: 'black',
                url: ``
            },
        ]
    }]
}