export default (instance:string) => {
    return [{
        slogan: '酒店集团发展',
        pageTitle: '评估与展望 - '+instance,
        pageTitleIcon: '',
        background_img: '/backgrounds/sunny.jpg',
        icons: [
            {
                name: '大数据',
                img: '/icons/大数据.svg',
                background: 'black',
                url: ``
            },
            {
                name: '趋势分析',
                img: '/icons/趋势分析.svg',
                background: 'black',
                url: ``
            },
            {
                name: '新开酒店',
                img: '/icons/新开酒店.svg',
                background: 'black',
                url: ``
            },
            {
                name: '财报',
                img: '/icons/财报分析.svg',
                background: 'black',
                url: `/datapage/${encodeURIComponent('内容文件/4.行业/3.展望/4.酒管集团季度财报/酒管集团季度财报.json')}?searchString=${instance}`
            },
            {
                name: '时间线',
                img: '/icons/时间线.svg',
                background: 'black',
                url: ``
            },
            {
                name: '对比分析',
                img: '/icons/对比分析.svg',
                background: 'black',
                url: ``
            },
            {
                name: '在建待建',
                img: '/icons/在建待建.svg',
                background: 'black',
                url: ``
            },
            {
                name: 'ESG',
                img: '/icons/ESG.svg',
                background: 'black',
                url: ``
            }
        ]
    }]
}