export default (c1:string, instance:string) => {
    console.log("handleInstance", c1, instance)
    switch(c1){
        case "品牌":
            window.location.href = `/startPage/brand?instance=${instance}`
            break
        case "城市":
            window.location.href = `/startPage/city?instance=${instance}`
            break
        case "酒店":
            window.location.href = `/startPage/hotel?instance=${instance}`
            break
    }
    if(instance.url){
        window.location.href = instance.url
    }

}