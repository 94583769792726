export default (instance:string) => {
    return [{
        slogan: '华美服务 = 咨询服务 + 华美云（四库赋能）',
        pageTitle: '资产管理',
        pageTitleIcon: '',
        background_img: '',
        icons: [
            {
                name: '业主代表',
                img: '/icons/业主代表.svg',
                background: 'black',
                url: `/startPage/owner`
            },
            {
                name: '预算审核',
                img: '/icons/预算审核.svg',
                background: 'black',
                url: `/startPage/budget`
            },
            {
                name: '业绩评估',
                img: '/icons/业绩评估.svg',
                background: 'black',
                url: `/startPage/performance`
            },
            {
                name: '经营提升',
                img: '/icons/经营提升.svg',
                background: 'black',
                url: `/startPage/operation`
            },
            {
                name: '人力资源',
                img: '/icons/人力资源.svg',
                background: 'black',
                url: `/startPage/humanResource`
            },
            {
                name: '资产保值增值',
                img: '/icons/资产保值增值.svg',
                background: 'black',
                url: `/startPage/assetValue`
            },
            {
                name: '',
                img: '',
                background: 'black',
                url: ''
            },
            {
                name: '',
                img: '',
                background: 'black'
            }
        ]
    }]
}