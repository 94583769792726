import asset from './asset'
import assetValue from './assetValue'
import brand from './brand'
import brandNegotiate from './brandNegotiate'
import budget from './budget'
import build from './build'
import city from './city'
import design from './design'
import feasibility from './feasibility'
import group from './group'
import homeGroup from './homeGroup'
import homeHuamei from './homeHuamei'
import homePartners from './homePartners'
import homeHotel from './homeHotel'
import homeOwner from './homeOwner'
import humanResource from './humanResource'
import opening from './opening'
import operation from './operation'
import owner from './owner'
import performance from './performance'
import purchase from './purchase'
import hotel from './hotel'


export default (category:string, instance:string) => {
    if(category == "asset"){
        return asset(instance)
    }
    if(category == "assetValue"){
        return assetValue(instance)
    }
    if(category == "brand"){
        return brand(instance)
    }
    if(category == "brandNegotiate"){
        return brandNegotiate(instance)
    }
    if(category == "budget"){
        return budget(instance)
    }
    if(category == "build"){
        return build(instance)
    }
    if(category == "city"){
        return city(instance)
    }
    if(category == "design"){
        return design(instance)
    }
    if(category == "feasibility"){
        return feasibility(instance)
    }
    if(category == "group"){
        return group(instance)
    }
    if(category == "homeHotel"){
        return homeHotel(instance)
    }
    if(category == "homeGroup"){
        return homeGroup(instance)
    }
    if(category == "homeOwner"){
        return homeOwner(instance)
    }
    if(category == "homeHuamei"){
        return homeHuamei(instance)
    }
    if(category == "homePartners"){
        return homePartners(instance)
    }
    if(category == "hotel"){
        return hotel(instance)
    }
    if(category == "humanResource"){
        return humanResource(instance)
    }
    if(category == "opening"){
        return opening(instance)
    }
    if(category == "operation"){
        return operation(instance)
    }
    if(category == "owner"){
        return owner(instance)
    }
    if(category == "performance"){
        return performance(instance)
    }
    if(category == "purchase"){
        return purchase(instance)
    }
}
