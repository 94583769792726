
import { updateAppConfig } from '#app/config'
import { defuFn } from 'defu'

const inlineConfig = {
  "nuxt": {
    "buildId": "5cb579b6-380d-499a-aa43-5464d5a6bcfb"
  }
}

// Vite - webpack is handled directly in #app/config
if (import.meta.hot) {
  import.meta.hot.accept((newModule) => {
    updateAppConfig(newModule.default)
  })
}

import cfg0 from "/Volumes/Tian_SanDisk/DEV/hm-cloud/frontend/app.config.ts"

export default /*@__PURE__*/ defuFn(cfg0, inlineConfig)
