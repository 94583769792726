import { useUserInfoStore } from '../stores/userInfoStore'

const getMineUrl = () => {
    const userInfoStore = useUserInfoStore()
    if(userInfoStore.info){
        console.log("getMineUrl userInfoStore.info", userInfoStore.info)
        switch(userInfoStore.info.user_type){
            case '华美员工':
                return `/startPage/homeHuamei`
            case '合作伙伴':
                return `/startPage/homePartners`
            case '集团':
                return `/startPage/homeGroup`
            case '新建酒店':
                return `/startPage/homeHotel`
            case '开业酒店':
                return `/startPage/homeOwner`
            default:
                return `/login`
        }
    }
    else{
        return `/login`
    }
}
export default defineNuxtRouteMiddleware((to, from) => {
    if (to.path === '/startPage/home') {
      return navigateTo(getMineUrl())
    }
  })


