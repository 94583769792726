export default (instance:string) => {
    return [{
        slogan: '华美服务 = 咨询服务 + 华美云（四库赋能）',
        pageTitle: '酒店建设',
        pageTitleIcon: '',
        background_img: '',
        icons: [
            {
                name: '定位可研',
                img: '/icons/定位可研.svg',
                background: 'black',
                url: `/startPage/feasibility`
            },
            {
                name: '品牌引进',
                img: '/icons/品牌引进.svg',
                background: 'black',
                url: `/startPage/brandNegotiate`
            },
            {
                name: '设计管理',
                img: '/icons/设计顾问.svg',
                background: 'black',
                url: `/startPage/design`
            },
            {
                name: '采购顾问',
                img: '/icons/采购顾问.svg',
                background: 'black',
                url: `/startPage/purchase`
            },
            {
                name: '开业筹备',
                img: '/icons/开业筹备.svg',
                background: 'black',
                url: `/startPage/opening`
            },
            {
                name: '',
                img: '',
                background: 'black',
                url: ''
            },
            {
                name: '',
                img: '',
                background: 'black',
                url: ''
            },
            {
                name: '',
                img: '',
                background: 'black'
            },
        ]
    }]
}