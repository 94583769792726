import { useUserInfoStore } from '@/stores/userInfoStore'

const authKeyTranslate = {
    "酒店":"可选酒店",
    "品牌":"可选品牌",
    "集团":"可选集团",
    "城市":"可选城市",
}
const multiAuths =  ["酒店", "品牌", "集团", "城市"]

export const checkAuth = (
    parentSectionName: string,
    childSectionName:string,
    instance:string,
) => {

    console.log("checkAuth", parentSectionName, childSectionName, instance)

    const userInfoStore = useUserInfoStore()
    const userInfo = userInfoStore.info as any

    if(
        userInfo.user_type == "华美员工"
    ){
        return true
    }
    if(
        (userInfo.user_type == "合作伙伴")
        && parentSectionName == "homePartners"
    ){
        return true
    }
    if(
        (userInfo.user_type == "集团")
        && parentSectionName == "homeGroup"
    ){
        return true
    }
    if(
        (userInfo.user_type == "新建酒店")
        && parentSectionName == "homeHotel"
    ){
        return true
    }
    if(
        (userInfo.user_type == "开业酒店")
        && parentSectionName == "homeOwner"
    ){
        return true
    }

    let authRange = "关闭"
    let authRangeList = []

    if(multiAuths.includes(parentSectionName)){
        authRange = userInfo[
            authKeyTranslate[parentSectionName]
        ] || ""
        authRangeList = authRange.split(",")
    }
    else{
        authRange = userInfo[`【权】${parentSectionName}/${childSectionName}`] || "关闭"
    }
    console.log("checkAuth", 
        parentSectionName, 
        childSectionName, 
        instance, 
        authRange)
    console.log("authRangeList", authRangeList)

    if(authRange == "关闭"){
        return false
    }

    if(authRange == "全部"){
        return true
    }

    if(authRangeList.includes(instance)){
        return true
    }

    if(authRange == "打开"){
        return true
    }

    return false
}