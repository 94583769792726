export default (instance:string) => {
    const trendFilePath = `内容文件/1.5.酒店/4.趋势/${instance}.pdf`;
    return [{
        slogan: '高端酒店发展',
        pageTitle: '评估与展望 - '+instance,
        pageTitleIcon: '',
        background_img: '/backgrounds/sunny.jpg',
        icons: [
            {
                name: '大数据',
                img: '/icons/大数据.svg',
                background: 'black',
                url: `/datapage/displayHotel?currHotel=${instance}`
            },
            {
                name: '趋势分析',
                img: '/icons/趋势分析.svg',
                background: 'black',
                url: `/datapage/${encodeURIComponent(trendFilePath)}`
            },
            {
                name: '对比分析',
                img: '/icons/对比分析.svg',
                background: 'black',
                url: `/datapage/compareHotel?currHotel=${instance}`
            }
        ]
    }]
}

