export default (instance:string) => {
    return [{
        slogan: '华美服务 = 咨询服务 + 华美云 + 尽管问',
        pageTitle: '我的华美云',
        pageTitleIcon: '/icons/华美云.svg',
        icons: [
        { 
            name: '我的项目',
            img: '/icons/我的项目.svg',
            url:'/instancePicker?c1=项目&datakey=我的项目'
        },
        { 
            name: '我的城市',
            img: '/icons/我的城市.svg',
            url:'/instancePicker?c1=城市&datakey=我的城市'
        },
        { 
            name: '我的品牌',
            img: '/icons/我的品牌.svg',
            url:'/instancePicker?c1=品牌&datakey=我的品牌'
        },
        { 
            name: '我的集团',
            img: '/icons/我的集团.svg',
            url:'/instancePicker?c1=集团&datakey=我的集团'
        },
        { 
            name: '关注酒店',
            img: '/icons/关注酒店.svg',
            url:'/instancePicker?c1=酒店&datakey=关注酒店'
        },
        { 
            name: '关注城市',
            img: '/icons/关注城市.svg',
            url:'/instancePicker?c1=城市&datakey=关注城市'
        },
        { 
            name: '关注品牌',
            img: '/icons/关注品牌.svg',
            url:'/instancePicker?c1=品牌&datakey=关注品牌'
        },
        { 
            name: '关注集团',
            img: '/icons/关注集团.svg',
            url:'/instancePicker?c1=集团&datakey=关注集团'
        },
        { 
            name: '酒店建设',
            img: '/icons/酒店建设.svg',
            url:'/startPage/build'
        },
        { 
            name: '酒店资管',
            img: '/icons/酒店资管.svg',
            url:'/startPage/asset'
        },
        { 
            name: '',
            img: '',
        },
        { 
            name: '',
            img: '',
        },
        { 
            name: '华美顾问',
            img: '/icons/华美顾问.svg',
            url: `/report-viewer?skip_decoding=1&report_path=${encodeURI("https://hm-cloud-v3.oss-cn-shenzhen.aliyuncs.com/%E5%86%85%E5%AE%B9%E6%96%87%E4%BB%B6/7.%E5%8D%8E%E7%BE%8E/1.%E5%8D%8E%E7%BE%8E%E9%A1%BE%E9%97%AE/1.%E5%8D%8E%E7%BE%8E%E7%AE%80%E4%BB%8B/%E5%8D%8E%E7%BE%8E%E9%A1%BE%E9%97%AE%E9%9B%86%E5%9B%A2%E7%AE%80%E4%BB%8B2024%7B4d1d76fb898a603952ff39200bd5feeaf42c1626a7248c34409e65fe4d873818%7D.pdf")}`
        },
        { 
            name: '华美年报',
            img: '/icons/华美年报.svg',
            url:"/datapage/内容文件%2F7.华美%2F3.华美研究%2F1.华美年报%2F?target_c2=华美年报"
        },
        { 
            name: '华美研究',
            img: '/icons/华美研究.svg',
            url: '/v3/内容文件%2F7.华美%2F3.华美研究%2F0.5.最新研究%2F?target_c2=最新研究',
        },
        { 
            name: '尽管问',
            img: '/icons/尽管问_黑.svg',
            url: '/v3/内容文件%2F7.华美%2F5.华美工具%2F1.尽管问%2F?target_c2=尽管问',
        },
        { 
            name: '行业报告',
            img: '/icons/行业报告.svg',
            
        },
        { 
            name: '',
            img: '',
        },
        { 
            name: '',
            img: '',
        },
        { 
            name: '',
            img: '',
        },
    ]}]
}