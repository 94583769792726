export default (token:string) => {
    var base64Url = token.split('.')[1] || "";
    if(!base64Url) return ""
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jwtString
    if(process.client){
        jwtString = decodeURIComponent(window.atob(base64).split('').map(function(c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));
    }else{
        jwtString = Buffer.from(base64, 'base64').toString()
    }

    const parsed = JSON.parse(jwtString)
    parsed.user_type_multi = parsed.user_type_multi || []
    return parsed;
}