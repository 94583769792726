export default (menu: any, menuPath: string) => {
    if(!menuPath) return {children:[]};
    const menuPathSplit = menuPath.split('/')
    let result  = menu
    for(let i = 0; i < menuPathSplit.length; i++){  
        result = result.children.find(x=>x.display == menuPathSplit[i])
        if(!result){
            return null
        }
    }
    return result
}